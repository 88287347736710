import React, { useState } from 'react'
import routes from "../../routes";
import "./navbar.css";
import Logout from "../auth/Logout";


const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <nav className="navbar">
            {localStorage.getItem('username') ?
                <div className="nav-brand">Hola {localStorage.getItem('username')}</div> : null}
            <div className={`nav-links ${showMenu ? 'show' : ''}`}>
                <a href={routes.MENU_MANTENCION}>Mantención</a>
            </div>
            <div className={`nav-buttons ${showMenu ? 'show' : ''}`}>
                {localStorage.getItem('isLoggedIn') ?
                    <Logout /> :
                    <>
                        <button>
                            <a href={routes.LOGIN}>Login</a>
                        </button>
                    </>
                }
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </nav>
    );
};


export default NavBar;