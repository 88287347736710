const routes = {
    // BACKEND: 'https://192.168.3.112:8000',
    // BACKEND: 'http://localhost:8000',
    BACKEND: 'https://bravo-operations.zapto.org',

    HOME: '/',
    LOGIN: '/login',

    STAGE_LIST: '/stages',

    TRUCK_LIST: '/trucks',
    TRUCK_DETAIL: '/trucks/:id',

    ROUTE_LIST: '/routes',
    ROUTE_DETAIL: '/routes/:id',
    
    WASTE_LIST: '/wastes',
    WASTE_DETAIL: '/wastes/:id',

    MAINTENANCE_LIST: '/maintenances',
    PENDING_WORK_ORDER_LIST: '/pending_work_orders',
    COMPLETED_WORK_ORDER_LIST: '/completed_work_orders',

    MAINTENANCE_PROGRAM_LIST: '/maintenance_programs',
    MAINTENANCE_PROGRAM_DETAIL: '/maintenance_programs/:id',

    EQUIPMENT_LIST: '/equipments',
    EQUIPMENT_DETAIL: '/equipments/:id',

    RFID_READER: '/rfid_reader',

    MENU_MANTENCION: '/mantencion',

    PROGRAMMED_INTERVENTION_LIST: '/programmed_interventions',

    QR_SCANNER: '/qr_scan',
};

export default routes;