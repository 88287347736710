import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import { useParams } from "react-router-dom";

const ProgrammedInterventionList = () => {
    const [programmedInterventions, setProgrammedInterventions] = useState([]);
    const [formDate, setFormDate] = useState({});
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    useEffect(() => {
        fetchProgrammedInterventions();
    }, []);

    const fetchProgrammedInterventions = async () => {
        try {
            const response = await apiConfig.get(`/api/programmed_interventions/`);
            setProgrammedInterventions(response.data);
            console.log('Stages fetched correctly');
        } catch (error) {
            console.error('Error fetching stages', error);
        }
    };

    const formatDateForInput = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate() + 1).padStart(2, '0'); // Fixed day increment
        return `${day}-${month}-${year}`; // Change the date format for input type="date"
    };

    const addTime = (date, quantity, interval) => {
        const newDate = new Date(date);
        if (interval === 'dia') {
            newDate.setDate(newDate.getDate() + quantity);
        } else if (interval === 'mes') {
            newDate.setMonth(newDate.getMonth() + quantity);
        }
        return newDate;
    };

    const isDateEarlierThanToday = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // reset time for comparison
        return date < today;
    };

    const handleDateChange = (e, id) => {
        setFormDate({ ...formDate, [id]: e.target.value });
    };

    const handleSubmit = async (programmed_intervention) => {
        const newDate = formDate[programmed_intervention.id];
        if (!newDate) {
            alert('Please select a valid date.');
            return;
        }

        setLoading(true);

        try {
            const payload = {
                equipment: programmed_intervention.equipment,
                programmed_intervention: programmed_intervention.id,
                description: programmed_intervention.name,
                reactive_or_preventive: "preventiva",
                date_due: newDate,
                ordered_by: 8,
            }
            const response = await apiConfig.post(`/api/equipment_work_orders/`, payload);

            alert('Próxima OT updated successfully!');
            fetchProgrammedInterventions();
        } catch (error) {
            console.error('Error updating Próxima OT', error);
            alert('Failed to update Próxima OT.');
        }

        setLoading(false);
    };

    const sortedInterventions = [...programmedInterventions].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div>
            <h1>Planes de Mantención</h1>
            {sortedInterventions && sortedInterventions.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => requestSort('name')}>Nombre</th>
                            <th onClick={() => requestSort('time_quantity')}>Cada</th>
                            <th onClick={() => requestSort('last_completed_work_order.datetime_completed')}>Última OT</th>
                            <th onClick={() => requestSort('next_pending_work_order.date_due')}>Próxima OT</th>
                            <th>Programar OT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedInterventions.map((programmed_intervention) => {
                            const lastCompletedOT = programmed_intervention.additional_info.last_completed_work_order?.datetime_completed
                                ? new Date(programmed_intervention.additional_info.last_completed_work_order.datetime_completed)
                                : null;
                            const nextPendingOT = programmed_intervention.additional_info.next_pending_work_order?.date_due
                                ? new Date(programmed_intervention.additional_info.next_pending_work_order.date_due)
                                : null;
                            const calculatedNextOT = lastCompletedOT
                                ? addTime(lastCompletedOT, programmed_intervention.time_quantity, programmed_intervention.time_interval)
                                : null;

                            const isNextOTInvalid = !nextPendingOT ||
                                nextPendingOT > calculatedNextOT ||
                                isDateEarlierThanToday(nextPendingOT);

                            // Prefill formDate for the specific intervention if it's not set
                            if (isNextOTInvalid && !formDate[programmed_intervention.id] && calculatedNextOT) {
                                setFormDate(prevState => ({
                                    ...prevState,
                                    [programmed_intervention.id]: formatDateForInput(calculatedNextOT)
                                }));
                            }

                            return (
                                <tr key={programmed_intervention.id}>
                                    <td>{programmed_intervention.name}</td>
                                    <td>{programmed_intervention.time_quantity} {programmed_intervention.time_interval}</td>
                                    <td>{lastCompletedOT ? formatDateForInput(lastCompletedOT) : 'N/A'}</td>
                                    <td style={{ backgroundColor: isNextOTInvalid ? 'red' : 'transparent' }}>
                                        {nextPendingOT ? formatDateForInput(nextPendingOT) : 'N/A'}
                                    </td>
                                    <td>
                                        {isNextOTInvalid && (
                                            <div>
                                                <input
                                                    type="date"
                                                    value={formDate[programmed_intervention.id] || (calculatedNextOT ? formatDateForInput(calculatedNextOT) : '')}
                                                    onChange={(e) => handleDateChange(e, programmed_intervention.id)}
                                                />
                                                <button onClick={() => handleSubmit(programmed_intervention)}>
                                                    {loading ? 'Guardando...' : 'Programar'}
                                                </button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <p>No hay camiones...</p>
            }
        </div>
    );
};

export default ProgrammedInterventionList;