import routes from "../routes";

const MenuMantencion = () => {
    return (
        <>
            <h1>Menú Mantención</h1>
            <ul>
                <li>
                    <a href={`${routes.MAINTENANCE_LIST}`}>Listado Equipos</a>
                </li>
                <li>
                    <a href={`${routes.COMPLETED_WORK_ORDER_LIST}`}>OT Completadas</a>
                </li>
                <li>
                    <a href={`${routes.PENDING_WORK_ORDER_LIST}`}>OT Pendientes</a>
                </li>
                <li>
                    <a href={`${routes.PROGRAMMED_INTERVENTION_LIST}`}>Planes Mantención</a>
                </li>
            </ul>
        </>
    );
};

export default MenuMantencion;