import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import routes from "../../routes";
import { useParams } from "react-router-dom";

const MaintenanceProgramDetail = () => {
    const { id } = useParams();
    const [maintenanceProgram, setMaintenanceProgram] = useState([]);

    useEffect(() => {
        fetchMaintenanceProgram();
    }, []);

    const fetchMaintenanceProgram = async () => {
        try {
            const response = await apiConfig.get(`/api/maintenance_programs/${id}/`);
            setMaintenanceProgram(response.data);
            console.log('Work orders fetched correctly');
        } catch (error) {
            console.error('Error fetching work orders', error);
        }
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);

        // Extract DD-MM-YYYY
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const getRowStyle = (status) => {
        switch (status) {
            case 'created':
                return { backgroundColor: 'yellow' };
            case 'in_progress':
                return { backgroundColor: 'lightblue' };
            case 'paused':
                return { backgroundColor: 'red' };
            case 'completed':
                return { backgroundColor: 'lightgreen' };
            default:
                return {};
        }
    };

    return (
        <div>
            {maintenanceProgram.equipment_work_orders && maintenanceProgram.equipment_work_orders.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Equipo</th>
                            <th>Mandante</th>
                            <th>Solicitud</th>
                            <th>Fecha Propuesta</th>
                            <th>Fecha Completado</th>
                            <th>HHs</th>
                            <th>Comentario</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maintenanceProgram.equipment_work_orders.map((workOrder) => (
                            <tr key={workOrder.id} style={getRowStyle(workOrder.status)}>
                                <td>{workOrder.id}</td>
                                <td>({workOrder.equipment.id}) {workOrder.equipment.name}</td>
                                <td>{workOrder.ordered_by.username}</td>
                                <td>{workOrder.description}</td>
                                <td>{workOrder.date_due}</td>
                                <td>{workOrder.datetime_completed ? formatTime(workOrder.datetime_completed) : null}</td>
                                <td>
                                    <ul>
                                        {Object.entries(workOrder.additional_info.hours_per_worker).map(([key, value]) => (
                                            <li key={key}>
                                                {key} : {value.toFixed(1)} horas
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>{workOrder.comment}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <p>No hay órdenes de trabajo...</p>
            }
        </div >
    );
};

export default MaintenanceProgramDetail;