import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import routes from "../../routes";

const MaintenanceProgramList = () => {
    const [maintenancePrograms, setMaintenancePrograms] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [programName, setProgramName] = useState("");  // New state for program name

    useEffect(() => {
        fetchMaintenancePrograms();
    }, []);

    const fetchMaintenancePrograms = async () => {
        try {
            const response = await apiConfig.get('/api/maintenance_programs/');
            setMaintenancePrograms(response.data);
        } catch (error) {
            console.error('Error fetching maintenance programs', error);
        }
    };

    const handleFileUpload = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('program_name', programName);  // Include program name in the form data

        try {
            const response = await apiConfig.post('/api/maintenance_programs/upload_maintenance_program/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('File uploaded successfully', response.data);
            fetchMaintenancePrograms();  // Fetch updated data
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const handleDownloadTemplate = async () => {
        try {
            const response = await apiConfig.get('/api/maintenance_programs/download_excel_template/', {
                responseType: 'blob',
            });

            // Create a link to download the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'maintenance_program_template.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading template', error);
        }
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const time = date.toTimeString().slice(0, 5);
        return `${day}-${month}-${year} ${time}`;
    };

    return (
        <div>
            <button onClick={handleDownloadTemplate}>Download Excel Template</button>

            <form onSubmit={handleFileUpload}>
                <label>
                    Maintenance Program Name:
                    <input type="text" value={programName} onChange={(e) => setProgramName(e.target.value)} required />
                </label>
                <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} required />
                <button type="submit">Upload Maintenance Program</button>
            </form>

            {maintenancePrograms.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Fecha Creado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maintenancePrograms.map((maintenanceProgram) => (
                            <tr key={maintenanceProgram.id}>
                                <td>{maintenanceProgram.id}</td>
                                <td><a href={`${routes.MAINTENANCE_PROGRAM_LIST}/${maintenanceProgram.id}`}>{maintenanceProgram.name}</a></td>
                                <td>{formatTime(maintenanceProgram.datetime_created)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay órdenes de trabajo...</p>
            )}
        </div>
    );
};

export default MaintenanceProgramList;