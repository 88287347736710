import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import routes from "./routes";
import NavBar from "./components/navbar/NavBar";
import Login from "./components/auth/Login";
import StageList from "./components/stages/StageList";
import TruckList from "./components/trucks/TruckList";
import TruckDetail from "./components/trucks/TruckDetail";
import RouteDetail from "./components/routes/RouteDetail";
import WasteDetail from "./components/wastes/WasteDetail";
import WasteList from "./components/wastes/WasteList";
import MaintenanceList from "./components/profiles/MaintenanceList";
import NfcReader from "./components/rfid/Rfid";
import PendingWorkOrderList from "./components/work_orders/PendingWorkOrderList";
import CompletedWorkOrderList from "./components/work_orders/CompletedWorkOrderList";
import MaintenanceProgramList from "./components/work_orders/MaintenanceProgramList";
import MaintenanceProgramDetail from "./components/work_orders/MaintenanceProgramDetail";
import EquipmentDetail from "./components/equipments/EquipmentDetail";
import MenuMantencion from "./components/MenuMantencion";
import ProgrammedInterventionList from "./components/programmed_interventions/ProgrammedInterventionList";
import QrScan from "./components/qr/QrScan";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path={routes.HOME} element="" />
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.STAGE_LIST} element={<StageList />} />
        <Route path={routes.TRUCK_LIST} element={<TruckList />} />
        <Route path={routes.TRUCK_DETAIL} element={<TruckDetail />} />
        <Route path={routes.ROUTE_DETAIL} element={<RouteDetail />} />
        <Route path={routes.WASTE_LIST} element={<WasteList />} />
        <Route path={routes.WASTE_DETAIL} element={<WasteDetail />} />
        <Route path={routes.MAINTENANCE_LIST} element={<MaintenanceList />} />
        <Route path={routes.PENDING_WORK_ORDER_LIST} element={<PendingWorkOrderList />} />
        <Route path={routes.COMPLETED_WORK_ORDER_LIST} element={<CompletedWorkOrderList />} />
        <Route path={routes.MAINTENANCE_PROGRAM_LIST} element={<MaintenanceProgramList />} />
        <Route path={routes.MAINTENANCE_PROGRAM_DETAIL} element={<MaintenanceProgramDetail />} />
        <Route path={routes.EQUIPMENT_DETAIL} element={<EquipmentDetail />} />
        <Route path={routes.MENU_MANTENCION} element={<MenuMantencion />} />
        <Route path={routes.PROGRAMMED_INTERVENTION_LIST} element={<ProgrammedInterventionList />} />
        <Route path={routes.QR_SCANNER} element={<QrScan />} />
        

        <Route path={routes.RFID_READER} element={<NfcReader />} />
      </Routes>
    </Router>
  );
}

export default App;
