import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './PendingWorkOrderList.css';

const PendingWorkOrderList = () => {
    const [pendingWorkOrders, setPendingWorkOrders] = useState([]);
    // const [unplannedWorkOrders, setUnplannedWorkOrders] = useState([]);
    // const [selectedDate, setSelectedDate] = useState(() => {
    //     const date = new Date();
    //     date.setHours(0, 0, 0, 0); // Set time to midnight
    //     return date;
    // }); // Preselect today's date

    useEffect(() => {
        fetchPendingWorkOrders();
        const interval = setInterval(fetchPendingWorkOrders, 5000); // Poll every 5 seconds
        return () => clearInterval(interval);
    }, []); // Run effect when selectedDate changes

    const fetchPendingWorkOrders = async () => {
        try {
            // const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : '';
            const response = await apiConfig.get(`/api/equipment_work_orders/get_pending_equipment_work_orders/`);
            // const planned = response.data;


            // const responseB = await apiConfig.get('/api/equipment_work_orders/get_unplanned_pending_equipment_work_orders/');
            const pending = response.data

            // setPlannedWorkOrders(planned);
            setPendingWorkOrders(pending);
        } catch (error) {
            console.error('Error fetching work orders', error);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    // const filterWorkOrdersByDate = (workOrders) => {
    //     if (!selectedDate) return workOrders;
    //     const selectedDateString = formatDate(selectedDate);
    //     return workOrders.filter(workOrder => workOrder.date_due === selectedDateString);
    // };

    const getRowStyle = (status) => {
        switch (status) {
            case 'created':
                return { backgroundColor: 'yellow' };
            case 'in_progress':
                return { backgroundColor: 'lightblue' };
            case 'paused':
                return { backgroundColor: 'red' };
            default:
                return {};
        }
    };

    return (
        <div className="work-orders-container">

            {/* Unplanned Work Orders */}
            <div className="work-orders-list unplanned-list">
                <h2 className="unplanned-title">OTs Pendientes</h2>
                {pendingWorkOrders.length > 0 ? (
                    <table className="work-order-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Equipo</th>
                                <th>Mandante</th>
                                <th>Solicitud</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingWorkOrders.map((workOrder) => (
                                <tr key={workOrder.id} style={getRowStyle(workOrder.status)}>
                                    <td>{workOrder.id}</td>
                                    <td>({workOrder.equipment.id}) {workOrder.equipment.name}</td>
                                    <td>{workOrder.ordered_by.username}</td>
                                    <td>{workOrder.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No unplanned work orders...</p>
                )}
            </div>
            {/* Planned Work Orders */}
            {/* <div className="work-orders-list planned-list">
                <h2 className="planned-title">OT Planificadas</h2>

                <div className="calendar-container">
                    <h3>Select a Date:</h3>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            if (date) {
                                const newDate = new Date(date);
                                newDate.setHours(0, 0, 0, 0); // Set time to midnight
                                setSelectedDate(newDate);
                            }
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select a date"
                        todayButton="Today"
                    />
                </div>

                {filterWorkOrdersByDate(pendingWorkOrders).length > 0 ? (
                    <table className="work-order-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Equipo</th>
                                <th>Mandante</th>
                                <th>Solicitud</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterWorkOrdersByDate(pendingWorkOrders).map((workOrder) => (
                                <tr key={workOrder.id} style={getRowStyle(workOrder.status)}>
                                    <td>{workOrder.id}</td>
                                    <td>({workOrder.equipment.id}) {workOrder.equipment.name}</td>
                                    <td>{workOrder.ordered_by.username}</td>
                                    <td>{workOrder.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No planned work orders for the selected date...</p>
                )}
            </div> */}
        </div>
    );
};

export default PendingWorkOrderList;