import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";

const CompletedWorkOrderList = () => {
    const [completedWorkOrders, setCompletedWorkOrders] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ordering, setOrdering] = useState('datetime_created');  // Track current ordering
    const [orderDirection, setOrderDirection] = useState('asc');   // Track current direction

    useEffect(() => {
        fetchCompletedWorkOrders();
    }, [ordering, orderDirection]);  // Refetch when ordering or direction changes

    const fetchCompletedWorkOrders = async (pageUrl = '/api/equipment_work_orders/get_completed_equipment_work_orders/') => {
        setLoading(true);
        try {
            const response = await apiConfig.get(pageUrl, {
                params: { ordering: `${orderDirection === 'asc' ? '' : '-'}${ordering}` }  // Handle asc/desc ordering
            });
            setCompletedWorkOrders(response.data.results);
            setNextPage(response.data.next);
            setPreviousPage(response.data.previous);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching work orders', error);
            setLoading(false);
        }
    };

    const handleSort = (column) => {
        if (ordering === column) {
            // Toggle the order direction if the same column is clicked
            setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new ordering column
            setOrdering(column);
            setOrderDirection('asc');  // Reset to ascending for the new column
        }
    };

    // Function to return the correct arrow based on current sort order
    const getSortArrow = (column) => {
        if (ordering === column) {
            return orderDirection === 'asc' ? '↑' : '↓';
        }
        return '';  // No arrow for unsorted columns
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const time = date.toTimeString().slice(0, 5);
        return `${day}-${month}-${year} ${time}`;
    };

    const getRowStyle = (status) => {
        switch (status) {
            case 'created':
                return { backgroundColor: 'yellow' };
            case 'in_progress':
                return { backgroundColor: 'green' };
            case 'paused':
                return { backgroundColor: 'red' };
            default:
                return {};
        }
    };

    const calculateHoursDifference = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const diffInMilliseconds = endDate - startDate;
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        return diffInHours.toFixed(1);
    };

    // Function to download the Excel file
    const downloadExcel = () => {
        const link = document.createElement('a');
        link.href = `${apiConfig.defaults.baseURL}api/equipment_work_orders/export_completed_work_orders_to_excel/`;
        link.setAttribute('download', 'completed_work_orders.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <h1>OTs Completadas</h1>

            {/* Download Button */}
            <button onClick={downloadExcel}>
                Download as Excel
            </button>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {completedWorkOrders.length > 0 ? (
                        <>
                            <div>
                                <button onClick={() => fetchCompletedWorkOrders(previousPage)} disabled={!previousPage}>
                                    Anterior
                                </button>
                                <button onClick={() => fetchCompletedWorkOrders(nextPage)} disabled={!nextPage}>
                                    Siguiente
                                </button>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('id')}>ID {getSortArrow('id')}</th>
                                        <th onClick={() => handleSort('equipment__name')}>Equipo {getSortArrow('equipment__name')}</th>
                                        <th onClick={() => handleSort('ordered_by__user__username')}>Mandante {getSortArrow('ordered_by__user__username')}</th>
                                        <th onClick={() => handleSort('description')}>Solicitud {getSortArrow('description')}</th>
                                        <th onClick={() => handleSort('datetime_created')}>Fecha Creado {getSortArrow('datetime_created')}</th>
                                        <th onClick={() => handleSort('datetime_completed')}>Fecha Completado {getSortArrow('datetime_completed')}</th>
                                        <th>Tiempo Respuesta</th>
                                        <th>HHs</th>
                                        <th onClick={() => handleSort('comment')}>Comentario {getSortArrow('comment')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {completedWorkOrders.map((workOrder) => (
                                        <tr key={workOrder.id} style={getRowStyle(workOrder.status)}>
                                            <td>{workOrder.id}</td>
                                            <td>({workOrder.equipment.id}) {workOrder.equipment.name}</td>
                                            <td>{workOrder.ordered_by.username}</td>
                                            <td>{workOrder.description}</td>
                                            <td>{formatTime(workOrder.datetime_created)}</td>
                                            <td>{formatTime(workOrder.datetime_completed)}</td>
                                            <td>{calculateHoursDifference(workOrder.datetime_created, workOrder.datetime_completed)} horas</td>
                                            <td>
                                                <ul>
                                                    {Object.entries(workOrder.additional_info.hours_per_worker).map(([key, value]) => (
                                                        <li key={key}>
                                                            {key} : {value.toFixed(1)} horas
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td>{workOrder.comment}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </>
                    ) : (
                        <p>No hay órdenes de trabajo...</p>
                    )}
                </>
            )}
        </div>
    );
};

export default CompletedWorkOrderList;